import { Router } from "aurelia-router";
import { Store, connectTo } from "aurelia-store";
import { State } from "state";
import { I18N } from "aurelia-i18n";
import * as localForage from 'localforage';
import { autoinject, bindable, bindingMode} from 'aurelia-framework';

@connectTo()
@autoinject()

export class Payments {
  private amount: number;
  private currentPage: string = "payment";
  private pageTitle: string = this.i18n.tr("bank.payment.make_payments");
  private state: State;
  private makePayment;
  private convertedAmount: number;
  private conversionTimer: any;
  private comment;
  private amount10;
  private paymail: string;
  private infoPayment;
  private infoReceiver;
  private locked: boolean = false;
  private isPaymail: boolean = false;
  private displayError: boolean = false;
  private errorMessage: string = "";
  private errorLink: boolean = false;
  private myWalletID: Record<string, string>
  private formattedAmount;
  @bindable matchResult: boolean;

  isRepayment: boolean = false

  constructor(
    private router: Router,
    private i18n: I18N,
    private store: Store<State>,
  ) {}

  activate(
    {
      identification,
      comment,
      amount,
      currency,
      name,
    } = {} as any
  ) {
      this.infoReceiver = {identification,  name};
      this.infoPayment = {comment, amount, currency};
      this.amount = amount;
      this.comment = comment;
      this.currency = currency;
      this.isItLocked();
  }

  isItLocked() {
    if (this.infoReceiver.identification != undefined && this.infoPayment.amount != undefined) {
      this.locked = true;
      this.amount = this.infoPayment.amount;
      this.store.dispatch('set.make_payment.recipient', this.infoReceiver);
      this.store.dispatch("set.make_payment.amount.and.currency", this.infoPayment.amount, this.infoPayment.currency);
      this.store.dispatch("set.make_payment.note", this.infoPayment.comment);
      if (this.isStateReady()) {
        this.updateFormattedAmountFromState();
      } else {
        // Si le state n'est pas prêt, on met en place une attente avec un délai
        this.waitForState();
      } 
    }
    else {
      this.locked = false;
    }
  }

  bind() {
    this.delay();
    // Vérifie si le state est déjà disponible au moment du bind
    if (this.state && this.state.makePayment && this.state.makePayment.amount) {
      // Déclenche la mise à jour du montant formaté une fois que le state est disponible
      this.updateFormattedAmountFromState();
    }
  }

  delay() {
    setTimeout(() => (this.checkSender()), 500);
  }

  checkSender() {
    this.state.makePayment.sender = this.state.wallets[this.state.selectedAccountIndex];

    if(this.state.makePayment.amount && this.state.makePayment.currency) {
      this.amount = this.state.makePayment.amount;
      this.comment = this.state.makePayment.note;
      this.currency = this.state.makePayment.currency ;
      this.isRepayment = true;
      if (this.state.makePayment.currency == "BSV") {
        this.amount10 = (this.state.makePayment.amount / 100000000).toFixed(8);    
      } else {
        this.amount10 = (this.state.makePayment.amount / 100).toFixed(2);    
      }
    }
  }

  goToBitcoinImport(accountIdentification: string) {
    this.router.navigateToRoute("bitcoin_import", {selectedPaymail: accountIdentification});
  }

  async goToReviewPayments(singleWebSocketInput) {
    // Check if Bitcoin or Bank
    if(this.paymail){
      let account = {
        name: "",
        identification: this.paymail
      };
      this.store.dispatch('set.make_payment.recipient', account);
      this.currency = this.state.makePayment.sender.currency;
      this.store.dispatch(
        "set.make_payment.amount.and.currency",
        this.amount,
        this.currency
      );
      this.store.dispatch("set.make_payment.note", this.comment);
      this.router.navigateToRoute("payments_recap");
      return
    }
    if (this.state.makePayment.sender.isBlockchain && this.state.makePayment.recipient.identification.includes("@")) {
      // If Bitcoin, it checks if Sender Paymail matches with imported wallet seed
      this.getMatchingPaymail(singleWebSocketInput).then((result) => {
        if (this.matchResult === true){
          if(this.state.makePayment.sender.balance >= this.amount) {
            if (this.paymail && this.state.makePayment.sender.isBlockchain) {
              let account = {
                name: "",
                identification: this.paymail
              };
              this.store.dispatch('set.make_payment.recipient', account);
              this.currency = "BSV";
              this.store.dispatch(
                "set.make_payment.amount.and.currency",
                this.amount,
                this.currency
              );
              this.store.dispatch("set.make_payment.note", this.comment);
              console.log("1");
              this.router.navigateToRoute("payments_recap");
            }
            this.currency = this.state.makePayment.sender.currency;
            this.store.dispatch(
              "set.make_payment.amount.and.currency",
              this.amount,
              this.currency
            );
            this.store.dispatch("set.make_payment.note", this.comment);
            console.log("2");
            this.router.navigateToRoute("payments_recap");
          }
          else {
            this.displayError = true;
            if(!this.state.makePayment.recipient.identification.includes("@")) {
              this.errorMessage = this.i18n.tr("error.payments.selling_bitcoin") //"Selling Bitcoin is not supported yet."
            } else{        
              this.errorMessage = this.i18n.tr("error.payments.not_enough_cash") //"You don't have enough cash to make this payment"
            }
          }
        } else {
          this.displayError = true;
          this.errorLink = true;
          this.errorMessage = this.i18n.tr("error.payments.wallet_not_on_device") //import wallet again
        }
      })
    }  else if (!this.state.makePayment.sender.isBlockchain){
      // If Bank
        if(this.state.makePayment.sender.balance >= this.amount/100) {
          
          if (this.paymail && !this.state.makePayment.sender.isBlockchain) {
              this.currency = this.state.makePayment.sender.currency;
              console.log("this is currency: "+ this.currency)
              let account = {
                name: "",
                identification: this.paymail
              };
              this.store.dispatch('set.make_payment.recipient', account);
              this.store.dispatch(
                "set.make_payment.amount.and.currency",
                this.amount,
                this.currency
              );
              this.store.dispatch("set.make_payment.note", this.comment);
              console.log("3");
              this.router.navigateToRoute("payments_recap");
          }
          else if (this.state.makePayment.recipient.identification.includes("@") && this.amount < 100) {
            this.displayError = true;      
            this.errorMessage = this.i18n.tr("error.payments.minimum_amount")
          }
          else {
            this.currency = this.state.makePayment.sender.currency;
            this.store.dispatch(
              "set.make_payment.amount.and.currency",
              this.amount,
              this.currency
            );
            this.store.dispatch("set.make_payment.note", this.comment);
            console.log("4");
            this.router.navigateToRoute("payments_recap");
          }
          
        } else {
            this.displayError = true;      
            this.errorMessage = this.i18n.tr("error.payments.not_enough_cash") //"You don't have enough cash to make this payment"
        }
      }
      else {
        this.displayError = true;
        if(!this.state.makePayment.recipient.identification.includes("@")) {
          this.errorMessage = this.i18n.tr("error.payments.selling_bitcoin") //"Selling Bitcoin is not supported yet."
        } else{        
          this.errorMessage = this.i18n.tr("error.payments.not_enough_cash") //"You don't have enough cash to make this payment"
        }
      }
  }  

  goToChooseWallet() {
    this.store.dispatch("set.make_payment.mk", true);
    this.router.navigateToRoute("choose_wallet");
  }

  goToContacts() {
    this.store.dispatch("set.make_payment.mk", true);
    this.router.navigateToRoute("contacts", { page: "mine" });
  }

  async getMatchingPaymail(singleWebSocketPaymailInput: string) {
    this.myWalletID = JSON.parse(await localForage.getItem('wallets_' + this.state.me._id)) as Record<string, string>;
    if (this.myWalletID) {
      let match = false; // Initialize a variable to keep track of whether a match has been found

      for (let i = 0; i < Object.keys(this.myWalletID).length; i++) {
        const entryIndex = Object.keys(this.myWalletID)[i];
        const entryValues = Object.values(this.myWalletID)[i];
        const paymail = Object.keys(entryValues).includes(singleWebSocketPaymailInput)
        const seedPhrase =  Object.values(entryValues)

        if (!paymail) {match = false;}
        else 
        if (paymail) {
          match = true; // Set 'found' to true only when a match is found
          break; // Exit the loop since we have already found a match
        }
      }      
      this.matchResult = match;
    }
  }

  amountFormattedRePayment : string;
  stateCurrencyRepayment: string;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) currency: string;

  async formatInputRePayment(event, currency) {
    let inputElement = event.target as HTMLInputElement;
    let inputValue = inputElement.value;
    this.currency = currency
    console.log(  "Currency : " + this.currency + currency
    )
    // Limit input length
    if (inputValue.length > 16) {
      inputValue = inputValue.slice(0, 16);
      inputElement.value = inputValue;
    }

    // Limit input length
    if (inputValue === "") {
      inputValue = "0"
      inputElement.value = inputValue;
    }
  
    this.state.makePayment.amount = (this.amount10 * 100000000).toFixed(0);
    this.checkSender();
    this.currency = this.state.makePayment.sender.currency;
    this.store.dispatch("set.make_payment.amount.and.currency",this.amount,this.currency);
  }

  onAmountInput(event) {
    let inputValue = event.target.value;
    this.currency = this.state.wallets[this.state.selectedAccountIndex].currency;
    console.log(`Input Value: ${inputValue}`);

    inputValue = inputValue.replace(/\D/g, '');
    console.log(`Filtered Input Value: ${inputValue}`);

    if (inputValue === '') {
      this.amount = 0;
      this.formattedAmount = this.currency === 'EUR' ? '0.00' : '00.00 000 000';
    } else {
      this.amount = Number(inputValue);
      console.log(`Updated amount: ${this.amount}`);

      if (this.currency === 'EUR') {
        console.log("EUR SCENA")
        let centimes = this.amount;

        this.formattedAmount = (centimes / 100).toLocaleString('fr-FR', { minimumFractionDigits: 2 });
        console.log(`EUR Formatted Amount: ${this.formattedAmount}`);
        this.store.dispatch("set.make_payment.amount.and.currency", centimes, this.currency);

      } else if (this.currency === 'BSV') {
        console.log("BSV SCENA")
        let satoshis = this.amount;
        console.log(`BSV Satoshis: ${satoshis}`);

        let btcValue = satoshis / 100000000;
        console.log(`BSV Value: ${btcValue}`);

        let formattedBtcValue = btcValue.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 });
        let parts = formattedBtcValue.split('.');
        if (parts.length > 1) {
          let integerPart = parts[0].padStart(2, '0');
          let decimalPart = parts[1];
          let formattedDecimal = decimalPart.slice(0, 2) + ' ' +
                                decimalPart.slice(2, 5) + ' ' +
                                decimalPart.slice(5, 8);
          this.formattedAmount = `${integerPart}.${formattedDecimal}`;
        } else {
          this.formattedAmount = formattedBtcValue;
        }
        this.store.dispatch("set.make_payment.amount.and.currency", satoshis, this.currency);
        console.log(`Final BSV Formatted Amount: ${this.formattedAmount}`);
      }
    }
  }

  onCommentChange(event) {
    this.comment = event.target.value;
    console.log(`Comment updated: ${this.comment}`);
    this.store.dispatch("set.make_payment.note", this.comment);
  }

  //format amount on repayment part
  updateFormattedAmountFromState() {
    console.log("rentré dans le format");
    this.currency = this.state.makePayment.currency;
    if (this.currency === 'EUR') {
      console.log("format - eur scena");
      // Convertir le montant en centimes pour l'EUR
      let centimes = parseInt(this.state.makePayment.amount, 10);
      this.formattedAmount = (centimes / 100).toLocaleString('fr-FR', { minimumFractionDigits: 2 });
    } else if (this.currency === 'BSV') {
      console.log("format - bsv scena");
      // Convertir le montant pour BitcoinSV
      let satoshis = parseInt(this.state.makePayment.amount, 10);
      let btcValue = satoshis / 100000000;
      let formattedBtcValue = btcValue.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 });
      
      let parts = formattedBtcValue.split('.');
      let integerPart = parts[0].padStart(2, '0');
      let decimalPart = parts[1];
      let formattedDecimal = decimalPart.slice(0, 2) + ' ' +
                            decimalPart.slice(2, 5) + ' ' +
                            decimalPart.slice(5, 8);
      
      this.formattedAmount = `${integerPart}.${formattedDecimal}`;
      console.log("formatted amount:" + this.formattedAmount);
    }
  }

  isStateReady() {
    return this.state && this.state.makePayment;
  }

  waitForState() {
    let checkStateInterval = setInterval(() => {
      if (this.isStateReady()) {
        clearInterval(checkStateInterval);
        this.updateFormattedAmountFromState();
      }
    }, 100); // Vérifie toutes les 100ms
  }
}
